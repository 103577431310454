@import './assets/styles/spark-animation.css';
@import './assets/styles/swiper.css';
@import './assets/styles/slider.css';
@import '~animate.css/animate.min.css';
/*@import "./assets/styles/tailwind.css";*/
@import 'ngx-toastr/toastr';
.duration-2000{
  transition-duration: 2000ms;
}


input:focus {
  border: 2px solid #363638!important;
  outline: none!important;
  box-shadow: none!important;
}
select:focus {
  border: 2px solid #363638!important;
  outline: none!important;
  box-shadow: none!important;
}
@font-face {
  /*font-family: 'Montserrat';*/
  font-weight: normal;
  font-style: normal;
}
html, body { height: 100%; }

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body { margin: 0; font-family:  'Open Sans'}
.banco{
  font-family: 'Banco Regular', sans-serif;
  /*color: #6bda90 !important;*/
}

@font-face {
  font-family: 'Banco Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Banco Regular'), url('./assets/fonts/banco/Banco Regular.woff') format('woff');
}
/*select:focus{*/
/*  border: none;*/
/*}*/
[type=checkbox]:checked {
  background-image: none; /*to remove the tickmark when checked*/
}
