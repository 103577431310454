html,

body {
  background-image: radial-gradient(#021027, #000000);
}



.circle-container {
  position: absolute;
  transform: translateX(-100vh);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.circle-container .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#ff8e3d, #ff8e3d 10%, rgba(255, 142, 61, 0) 56%);
  -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
  animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}
@-webkit-keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
.circle-container:nth-child(1) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-1;
  animation-name: move-frames-1;
  -webkit-animation-duration: 15055ms;
  animation-duration: 15055ms;
  -webkit-animation-delay: 9709ms;
  animation-delay: 9709ms;
}
@-webkit-keyframes move-frames-1 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }
  to {
    transform: translate3d(37vw, -117vh, 0);
  }
}
@keyframes move-frames-1 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }
  to {
    transform: translate3d(37vw, -117vh, 0);
  }
}
.circle-container:nth-child(1) .circle {
  -webkit-animation-delay: 1273ms;
  animation-delay: 1273ms;
}
.circle-container:nth-child(2) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-2;
  animation-name: move-frames-2;
  -webkit-animation-duration: 22844ms;
  animation-duration: 22844ms;
  -webkit-animation-delay: 5936ms;
  animation-delay: 5936ms;
}
@-webkit-keyframes move-frames-2 {
  from {
    transform: translate3d(31vw, 109vh, 0);
  }
  to {
    transform: translate3d(64vw, -138vh, 0);
  }
}
@keyframes move-frames-2 {
  from {
    transform: translate3d(31vw, 109vh, 0);
  }
  to {
    transform: translate3d(64vw, -138vh, 0);
  }
}
.circle-container:nth-child(2) .circle {
  -webkit-animation-delay: 2129ms;
  animation-delay: 2129ms;
}
.circle-container:nth-child(3) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-3;
  animation-name: move-frames-3;
  -webkit-animation-duration: 20808ms;
  animation-duration: 20808ms;
  -webkit-animation-delay: 5100ms;
  animation-delay: 5100ms;
}
@-webkit-keyframes move-frames-3 {
  from {
    transform: translate3d(87vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -116vh, 0);
  }
}
@keyframes move-frames-3 {
  from {
    transform: translate3d(87vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -116vh, 0);
  }
}
.circle-container:nth-child(3) .circle {
  -webkit-animation-delay: 387ms;
  animation-delay: 387ms;
}
.circle-container:nth-child(4) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-4;
  animation-name: move-frames-4;
  -webkit-animation-duration: 23674ms;
  animation-duration: 23674ms;
  -webkit-animation-delay: 8790ms;
  animation-delay: 8790ms;
}
@-webkit-keyframes move-frames-4 {
  from {
    transform: translate3d(71vw, 101vh, 0);
  }
  to {
    transform: translate3d(78vw, -115vh, 0);
  }
}
@keyframes move-frames-4 {
  from {
    transform: translate3d(71vw, 101vh, 0);
  }
  to {
    transform: translate3d(78vw, -115vh, 0);
  }
}
.circle-container:nth-child(4) .circle {
  -webkit-animation-delay: 334ms;
  animation-delay: 334ms;
}
.circle-container:nth-child(5) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-5;
  animation-name: move-frames-5;
  -webkit-animation-duration: 23811ms;
  animation-duration: 23811ms;
  -webkit-animation-delay: 6910ms;
  animation-delay: 6910ms;
}
@-webkit-keyframes move-frames-5 {
  from {
    transform: translate3d(26vw, 109vh, 0);
  }
  to {
    transform: translate3d(58vw, -117vh, 0);
  }
}
@keyframes move-frames-5 {
  from {
    transform: translate3d(26vw, 109vh, 0);
  }
  to {
    transform: translate3d(58vw, -117vh, 0);
  }
}
.circle-container:nth-child(5) .circle {
  -webkit-animation-delay: 1363ms;
  animation-delay: 1363ms;
}
.circle-container:nth-child(6) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-6;
  animation-name: move-frames-6;
  -webkit-animation-duration: 14425ms;
  animation-duration: 14425ms;
  -webkit-animation-delay: 6353ms;
  animation-delay: 6353ms;
}
@-webkit-keyframes move-frames-6 {
  from {
    transform: translate3d(100vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -122vh, 0);
  }
}
@keyframes move-frames-6 {
  from {
    transform: translate3d(100vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -122vh, 0);
  }
}
.circle-container:nth-child(6) .circle {
  -webkit-animation-delay: 777ms;
  animation-delay: 777ms;
}
.circle-container:nth-child(7) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-7;
  animation-name: move-frames-7;
  -webkit-animation-duration: 14690ms;
  animation-duration: 14690ms;
  -webkit-animation-delay: 8430ms;
  animation-delay: 8430ms;
}
@-webkit-keyframes move-frames-7 {
  from {
    transform: translate3d(2vw, 102vh, 0);
  }
  to {
    transform: translate3d(82vw, -110vh, 0);
  }
}
@keyframes move-frames-7 {
  from {
    transform: translate3d(2vw, 102vh, 0);
  }
  to {
    transform: translate3d(82vw, -110vh, 0);
  }
}
.circle-container:nth-child(7) .circle {
  -webkit-animation-delay: 1216ms;
  animation-delay: 1216ms;
}
.circle-container:nth-child(8) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-8;
  animation-name: move-frames-8;
  -webkit-animation-duration: 16071ms;
  animation-duration: 16071ms;
  -webkit-animation-delay: 4171ms;
  animation-delay: 4171ms;
}
@-webkit-keyframes move-frames-8 {
  from {
    transform: translate3d(60vw, 105vh, 0);
  }
  to {
    transform: translate3d(12vw, -113vh, 0);
  }
}
@keyframes move-frames-8 {
  from {
    transform: translate3d(60vw, 105vh, 0);
  }
  to {
    transform: translate3d(12vw, -113vh, 0);
  }
}
.circle-container:nth-child(8) .circle {
  -webkit-animation-delay: 708ms;
  animation-delay: 708ms;
}
.circle-container:nth-child(9) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-9;
  animation-name: move-frames-9;
  -webkit-animation-duration: 23274ms;
  animation-duration: 23274ms;
  -webkit-animation-delay: 2844ms;
  animation-delay: 2844ms;
}
@-webkit-keyframes move-frames-9 {
  from {
    transform: translate3d(45vw, 109vh, 0);
  }
  to {
    transform: translate3d(6vw, -126vh, 0);
  }
}
@keyframes move-frames-9 {
  from {
    transform: translate3d(45vw, 109vh, 0);
  }
  to {
    transform: translate3d(6vw, -126vh, 0);
  }
}
.circle-container:nth-child(9) .circle {
  -webkit-animation-delay: 1906ms;
  animation-delay: 1906ms;
}
.circle-container:nth-child(10) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-10;
  animation-name: move-frames-10;
  -webkit-animation-duration: 16830ms;
  animation-duration: 16830ms;
  -webkit-animation-delay: 6114ms;
  animation-delay: 6114ms;
}
@-webkit-keyframes move-frames-10 {
  from {
    transform: translate3d(60vw, 102vh, 0);
  }
  to {
    transform: translate3d(23vw, -112vh, 0);
  }
}
@keyframes move-frames-10 {
  from {
    transform: translate3d(60vw, 102vh, 0);
  }
  to {
    transform: translate3d(23vw, -112vh, 0);
  }
}
.circle-container:nth-child(10) .circle {
  -webkit-animation-delay: 3626ms;
  animation-delay: 3626ms;
}
.circle-container:nth-child(11) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-11;
  animation-name: move-frames-11;
  -webkit-animation-duration: 14851ms;
  animation-duration: 14851ms;
  -webkit-animation-delay: 9560ms;
  animation-delay: 9560ms;
}
@-webkit-keyframes move-frames-11 {
  from {
    transform: translate3d(78vw, 108vh, 0);
  }
  to {
    transform: translate3d(66vw, -131vh, 0);
  }
}
@keyframes move-frames-11 {
  from {
    transform: translate3d(78vw, 108vh, 0);
  }
  to {
    transform: translate3d(66vw, -131vh, 0);
  }
}
.circle-container:nth-child(11) .circle {
  -webkit-animation-delay: 3259ms;
  animation-delay: 3259ms;
}
.circle-container:nth-child(12) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-12;
  animation-name: move-frames-12;
  -webkit-animation-duration: 21259ms;
  animation-duration: 21259ms;
  -webkit-animation-delay: 901ms;
  animation-delay: 901ms;
}
@-webkit-keyframes move-frames-12 {
  from {
    transform: translate3d(69vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -113vh, 0);
  }
}
@keyframes move-frames-12 {
  from {
    transform: translate3d(69vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -113vh, 0);
  }
}
.circle-container:nth-child(12) .circle {
  -webkit-animation-delay: 1543ms;
  animation-delay: 1543ms;
}
.circle-container:nth-child(13) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-13;
  animation-name: move-frames-13;
  -webkit-animation-duration: 23450ms;
  animation-duration: 23450ms;
  -webkit-animation-delay: 2378ms;
  animation-delay: 2378ms;
}
@-webkit-keyframes move-frames-13 {
  from {
    transform: translate3d(57vw, 109vh, 0);
  }
  to {
    transform: translate3d(65vw, -138vh, 0);
  }
}
@keyframes move-frames-13 {
  from {
    transform: translate3d(57vw, 109vh, 0);
  }
  to {
    transform: translate3d(65vw, -138vh, 0);
  }
}
.circle-container:nth-child(13) .circle {
  -webkit-animation-delay: 2977ms;
  animation-delay: 2977ms;
}
.circle-container:nth-child(14) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-14;
  animation-name: move-frames-14;
  -webkit-animation-duration: 23006ms;
  animation-duration: 23006ms;
  -webkit-animation-delay: 9892ms;
  animation-delay: 9892ms;
}
@-webkit-keyframes move-frames-14 {
  from {
    transform: translate3d(32vw, 107vh, 0);
  }
  to {
    transform: translate3d(15vw, -116vh, 0);
  }
}
@keyframes move-frames-14 {
  from {
    transform: translate3d(32vw, 107vh, 0);
  }
  to {
    transform: translate3d(15vw, -116vh, 0);
  }
}
.circle-container:nth-child(14) .circle {
  -webkit-animation-delay: 3337ms;
  animation-delay: 3337ms;
}
.circle-container:nth-child(15) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-15;
  animation-name: move-frames-15;
  -webkit-animation-duration: 19058ms;
  animation-duration: 19058ms;
  -webkit-animation-delay: 6837ms;
  animation-delay: 6837ms;
}
@-webkit-keyframes move-frames-15 {
  from {
    transform: translate3d(1vw, 107vh, 0);
  }
  to {
    transform: translate3d(57vw, -135vh, 0);
  }
}
@keyframes move-frames-15 {
  from {
    transform: translate3d(1vw, 107vh, 0);
  }
  to {
    transform: translate3d(57vw, -135vh, 0);
  }
}
.circle-container:nth-child(15) .circle {
  -webkit-animation-delay: 2658ms;
  animation-delay: 2658ms;
}
.circle-container:nth-child(16) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-16;
  animation-name: move-frames-16;
  -webkit-animation-duration: 14293ms;
  animation-duration: 14293ms;
  -webkit-animation-delay: 6458ms;
  animation-delay: 6458ms;
}
@-webkit-keyframes move-frames-16 {
  from {
    transform: translate3d(87vw, 108vh, 0);
  }
  to {
    transform: translate3d(70vw, -122vh, 0);
  }
}
@keyframes move-frames-16 {
  from {
    transform: translate3d(87vw, 108vh, 0);
  }
  to {
    transform: translate3d(70vw, -122vh, 0);
  }
}
.circle-container:nth-child(16) .circle {
  -webkit-animation-delay: 179ms;
  animation-delay: 179ms;
}
.circle-container:nth-child(17) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-17;
  animation-name: move-frames-17;
  -webkit-animation-duration: 17635ms;
  animation-duration: 17635ms;
  -webkit-animation-delay: 6125ms;
  animation-delay: 6125ms;
}
@-webkit-keyframes move-frames-17 {
  from {
    transform: translate3d(7vw, 110vh, 0);
  }
  to {
    transform: translate3d(40vw, -127vh, 0);
  }
}
@keyframes move-frames-17 {
  from {
    transform: translate3d(7vw, 110vh, 0);
  }
  to {
    transform: translate3d(40vw, -127vh, 0);
  }
}
.circle-container:nth-child(17) .circle {
  -webkit-animation-delay: 104ms;
  animation-delay: 104ms;
}
.circle-container:nth-child(18) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-18;
  animation-name: move-frames-18;
  -webkit-animation-duration: 20072ms;
  animation-duration: 20072ms;
  -webkit-animation-delay: 7049ms;
  animation-delay: 7049ms;
}
@-webkit-keyframes move-frames-18 {
  from {
    transform: translate3d(87vw, 105vh, 0);
  }
  to {
    transform: translate3d(54vw, -134vh, 0);
  }
}
@keyframes move-frames-18 {
  from {
    transform: translate3d(87vw, 105vh, 0);
  }
  to {
    transform: translate3d(54vw, -134vh, 0);
  }
}
.circle-container:nth-child(18) .circle {
  -webkit-animation-delay: 397ms;
  animation-delay: 397ms;
}
.circle-container:nth-child(19) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-19;
  animation-name: move-frames-19;
  -webkit-animation-duration: 21722ms;
  animation-duration: 21722ms;
  -webkit-animation-delay: 2686ms;
  animation-delay: 2686ms;
}
@-webkit-keyframes move-frames-19 {
  from {
    transform: translate3d(11vw, 104vh, 0);
  }
  to {
    transform: translate3d(94vw, -117vh, 0);
  }
}
@keyframes move-frames-19 {
  from {
    transform: translate3d(11vw, 104vh, 0);
  }
  to {
    transform: translate3d(94vw, -117vh, 0);
  }
}
.circle-container:nth-child(19) .circle {
  -webkit-animation-delay: 1753ms;
  animation-delay: 1753ms;
}
.circle-container:nth-child(20) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-20;
  animation-name: move-frames-20;
  -webkit-animation-duration: 16999ms;
  animation-duration: 16999ms;
  -webkit-animation-delay: 2466ms;
  animation-delay: 2466ms;
}
@-webkit-keyframes move-frames-20 {
  from {
    transform: translate3d(31vw, 110vh, 0);
  }
  to {
    transform: translate3d(25vw, -111vh, 0);
  }
}
@keyframes move-frames-20 {
  from {
    transform: translate3d(31vw, 110vh, 0);
  }
  to {
    transform: translate3d(25vw, -111vh, 0);
  }
}
.circle-container:nth-child(20) .circle {
  -webkit-animation-delay: 1251ms;
  animation-delay: 1251ms;
}
.circle-container:nth-child(21) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-21;
  animation-name: move-frames-21;
  -webkit-animation-duration: 20153ms;
  animation-duration: 20153ms;
  -webkit-animation-delay: 4549ms;
  animation-delay: 4549ms;
}
@-webkit-keyframes move-frames-21 {
  from {
    transform: translate3d(79vw, 110vh, 0);
  }
  to {
    transform: translate3d(7vw, -138vh, 0);
  }
}
@keyframes move-frames-21 {
  from {
    transform: translate3d(79vw, 110vh, 0);
  }
  to {
    transform: translate3d(7vw, -138vh, 0);
  }
}
.circle-container:nth-child(21) .circle {
  -webkit-animation-delay: 1647ms;
  animation-delay: 1647ms;
}
.circle-container:nth-child(22) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-22;
  animation-name: move-frames-22;
  -webkit-animation-duration: 16171ms;
  animation-duration: 16171ms;
  -webkit-animation-delay: 4117ms;
  animation-delay: 4117ms;
}
@-webkit-keyframes move-frames-22 {
  from {
    transform: translate3d(69vw, 105vh, 0);
  }
  to {
    transform: translate3d(37vw, -120vh, 0);
  }
}
@keyframes move-frames-22 {
  from {
    transform: translate3d(69vw, 105vh, 0);
  }
  to {
    transform: translate3d(37vw, -120vh, 0);
  }
}
.circle-container:nth-child(22) .circle {
  -webkit-animation-delay: 648ms;
  animation-delay: 648ms;
}
.circle-container:nth-child(23) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-23;
  animation-name: move-frames-23;
  -webkit-animation-duration: 21427ms;
  animation-duration: 21427ms;
  -webkit-animation-delay: 10721ms;
  animation-delay: 10721ms;
}
@-webkit-keyframes move-frames-23 {
  from {
    transform: translate3d(94vw, 110vh, 0);
  }
  to {
    transform: translate3d(17vw, -132vh, 0);
  }
}
@keyframes move-frames-23 {
  from {
    transform: translate3d(94vw, 110vh, 0);
  }
  to {
    transform: translate3d(17vw, -132vh, 0);
  }
}
.circle-container:nth-child(23) .circle {
  -webkit-animation-delay: 3187ms;
  animation-delay: 3187ms;
}
.circle-container:nth-child(24) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-24;
  animation-name: move-frames-24;
  -webkit-animation-duration: 18178ms;
  animation-duration: 18178ms;
  -webkit-animation-delay: 9381ms;
  animation-delay: 9381ms;
}
@-webkit-keyframes move-frames-24 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }
  to {
    transform: translate3d(37vw, -117vh, 0);
  }
}
@keyframes move-frames-24 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }
  to {
    transform: translate3d(37vw, -117vh, 0);
  }
}
.circle-container:nth-child(24) .circle {
  -webkit-animation-delay: 3268ms;
  animation-delay: 3268ms;
}
.circle-container:nth-child(25) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-25;
  animation-name: move-frames-25;
  -webkit-animation-duration: 15579ms;
  animation-duration: 15579ms;
  -webkit-animation-delay: 7225ms;
  animation-delay: 7225ms;
}
@-webkit-keyframes move-frames-25 {
  from {
    transform: translate3d(51vw, 107vh, 0);
  }
  to {
    transform: translate3d(26vw, -120vh, 0);
  }
}
@keyframes move-frames-25 {
  from {
    transform: translate3d(51vw, 107vh, 0);
  }
  to {
    transform: translate3d(26vw, -120vh, 0);
  }
}
.circle-container:nth-child(25) .circle {
  -webkit-animation-delay: 2512ms;
  animation-delay: 2512ms;
}
.circle-container:nth-child(26) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-26;
  animation-name: move-frames-26;
  -webkit-animation-duration: 17565ms;
  animation-duration: 17565ms;
  -webkit-animation-delay: 6516ms;
  animation-delay: 6516ms;
}
@-webkit-keyframes move-frames-26 {
  from {
    transform: translate3d(100vw, 110vh, 0);
  }
  to {
    transform: translate3d(30vw, -122vh, 0);
  }
}
@keyframes move-frames-26 {
  from {
    transform: translate3d(100vw, 110vh, 0);
  }
  to {
    transform: translate3d(30vw, -122vh, 0);
  }
}
.circle-container:nth-child(26) .circle {
  -webkit-animation-delay: 3436ms;
  animation-delay: 3436ms;
}
.circle-container:nth-child(27) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-27;
  animation-name: move-frames-27;
  -webkit-animation-duration: 23617ms;
  animation-duration: 23617ms;
  -webkit-animation-delay: 7709ms;
  animation-delay: 7709ms;
}
@-webkit-keyframes move-frames-27 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(85vw, -112vh, 0);
  }
}
@keyframes move-frames-27 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(85vw, -112vh, 0);
  }
}
.circle-container:nth-child(27) .circle {
  -webkit-animation-delay: 2024ms;
  animation-delay: 2024ms;
}
.circle-container:nth-child(28) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-28;
  animation-name: move-frames-28;
  -webkit-animation-duration: 21040ms;
  animation-duration: 21040ms;
  -webkit-animation-delay: 121ms;
  animation-delay: 121ms;
}
@-webkit-keyframes move-frames-28 {
  from {
    transform: translate3d(43vw, 105vh, 0);
  }
  to {
    transform: translate3d(19vw, -130vh, 0);
  }
}
@keyframes move-frames-28 {
  from {
    transform: translate3d(43vw, 105vh, 0);
  }
  to {
    transform: translate3d(19vw, -130vh, 0);
  }
}
.circle-container:nth-child(28) .circle {
  -webkit-animation-delay: 78ms;
  animation-delay: 78ms;
}
.circle-container:nth-child(29) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-29;
  animation-name: move-frames-29;
  -webkit-animation-duration: 23300ms;
  animation-duration: 23300ms;
  -webkit-animation-delay: 10255ms;
  animation-delay: 10255ms;
}
@-webkit-keyframes move-frames-29 {
  from {
    transform: translate3d(81vw, 101vh, 0);
  }
  to {
    transform: translate3d(52vw, -122vh, 0);
  }
}
@keyframes move-frames-29 {
  from {
    transform: translate3d(81vw, 101vh, 0);
  }
  to {
    transform: translate3d(52vw, -122vh, 0);
  }
}
.circle-container:nth-child(29) .circle {
  -webkit-animation-delay: 2829ms;
  animation-delay: 2829ms;
}
.circle-container:nth-child(30) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-30;
  animation-name: move-frames-30;
  -webkit-animation-duration: 18893ms;
  animation-duration: 18893ms;
  -webkit-animation-delay: 6014ms;
  animation-delay: 6014ms;
}
@-webkit-keyframes move-frames-30 {
  from {
    transform: translate3d(18vw, 104vh, 0);
  }
  to {
    transform: translate3d(64vw, -109vh, 0);
  }
}
@keyframes move-frames-30 {
  from {
    transform: translate3d(18vw, 104vh, 0);
  }
  to {
    transform: translate3d(64vw, -109vh, 0);
  }
}
.circle-container:nth-child(30) .circle {
  -webkit-animation-delay: 822ms;
  animation-delay: 822ms;
}
.circle-container:nth-child(31) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-31;
  animation-name: move-frames-31;
  -webkit-animation-duration: 15125ms;
  animation-duration: 15125ms;
  -webkit-animation-delay: 4964ms;
  animation-delay: 4964ms;
}
@-webkit-keyframes move-frames-31 {
  from {
    transform: translate3d(58vw, 103vh, 0);
  }
  to {
    transform: translate3d(52vw, -117vh, 0);
  }
}
@keyframes move-frames-31 {
  from {
    transform: translate3d(58vw, 103vh, 0);
  }
  to {
    transform: translate3d(52vw, -117vh, 0);
  }
}
.circle-container:nth-child(31) .circle {
  -webkit-animation-delay: 1304ms;
  animation-delay: 1304ms;
}
.circle-container:nth-child(32) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-32;
  animation-name: move-frames-32;
  -webkit-animation-duration: 16245ms;
  animation-duration: 16245ms;
  -webkit-animation-delay: 2160ms;
  animation-delay: 2160ms;
}
@-webkit-keyframes move-frames-32 {
  from {
    transform: translate3d(22vw, 107vh, 0);
  }
  to {
    transform: translate3d(46vw, -125vh, 0);
  }
}
@keyframes move-frames-32 {
  from {
    transform: translate3d(22vw, 107vh, 0);
  }
  to {
    transform: translate3d(46vw, -125vh, 0);
  }
}
.circle-container:nth-child(32) .circle {
  -webkit-animation-delay: 3575ms;
  animation-delay: 3575ms;
}
.circle-container:nth-child(33) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-33;
  animation-name: move-frames-33;
  -webkit-animation-duration: 17031ms;
  animation-duration: 17031ms;
  -webkit-animation-delay: 9708ms;
  animation-delay: 9708ms;
}
@-webkit-keyframes move-frames-33 {
  from {
    transform: translate3d(35vw, 104vh, 0);
  }
  to {
    transform: translate3d(88vw, -111vh, 0);
  }
}
@keyframes move-frames-33 {
  from {
    transform: translate3d(35vw, 104vh, 0);
  }
  to {
    transform: translate3d(88vw, -111vh, 0);
  }
}
.circle-container:nth-child(33) .circle {
  -webkit-animation-delay: 1043ms;
  animation-delay: 1043ms;
}
.circle-container:nth-child(34) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-34;
  animation-name: move-frames-34;
  -webkit-animation-duration: 14528ms;
  animation-duration: 14528ms;
  -webkit-animation-delay: 7359ms;
  animation-delay: 7359ms;
}
@-webkit-keyframes move-frames-34 {
  from {
    transform: translate3d(91vw, 105vh, 0);
  }
  to {
    transform: translate3d(38vw, -132vh, 0);
  }
}
@keyframes move-frames-34 {
  from {
    transform: translate3d(91vw, 105vh, 0);
  }
  to {
    transform: translate3d(38vw, -132vh, 0);
  }
}
.circle-container:nth-child(34) .circle {
  -webkit-animation-delay: 3094ms;
  animation-delay: 3094ms;
}
.circle-container:nth-child(35) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-35;
  animation-name: move-frames-35;
  -webkit-animation-duration: 23529ms;
  animation-duration: 23529ms;
  -webkit-animation-delay: 4171ms;
  animation-delay: 4171ms;
}
@-webkit-keyframes move-frames-35 {
  from {
    transform: translate3d(89vw, 107vh, 0);
  }
  to {
    transform: translate3d(100vw, -122vh, 0);
  }
}
@keyframes move-frames-35 {
  from {
    transform: translate3d(89vw, 107vh, 0);
  }
  to {
    transform: translate3d(100vw, -122vh, 0);
  }
}
.circle-container:nth-child(35) .circle {
  -webkit-animation-delay: 1322ms;
  animation-delay: 1322ms;
}
.circle-container:nth-child(36) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-36;
  animation-name: move-frames-36;
  -webkit-animation-duration: 17976ms;
  animation-duration: 17976ms;
  -webkit-animation-delay: 4360ms;
  animation-delay: 4360ms;
}
@-webkit-keyframes move-frames-36 {
  from {
    transform: translate3d(47vw, 104vh, 0);
  }
  to {
    transform: translate3d(98vw, -115vh, 0);
  }
}
@keyframes move-frames-36 {
  from {
    transform: translate3d(47vw, 104vh, 0);
  }
  to {
    transform: translate3d(98vw, -115vh, 0);
  }
}
.circle-container:nth-child(36) .circle {
  -webkit-animation-delay: 3177ms;
  animation-delay: 3177ms;
}
.circle-container:nth-child(37) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-37;
  animation-name: move-frames-37;
  -webkit-animation-duration: 21426ms;
  animation-duration: 21426ms;
  -webkit-animation-delay: 5788ms;
  animation-delay: 5788ms;
}
@-webkit-keyframes move-frames-37 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }
  to {
    transform: translate3d(83vw, -121vh, 0);
  }
}
@keyframes move-frames-37 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }
  to {
    transform: translate3d(83vw, -121vh, 0);
  }
}
.circle-container:nth-child(37) .circle {
  -webkit-animation-delay: 2238ms;
  animation-delay: 2238ms;
}
.circle-container:nth-child(38) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-38;
  animation-name: move-frames-38;
  -webkit-animation-duration: 22022ms;
  animation-duration: 22022ms;
  -webkit-animation-delay: 6927ms;
  animation-delay: 6927ms;
}
@-webkit-keyframes move-frames-38 {
  from {
    transform: translate3d(41vw, 110vh, 0);
  }
  to {
    transform: translate3d(16vw, -126vh, 0);
  }
}
@keyframes move-frames-38 {
  from {
    transform: translate3d(41vw, 110vh, 0);
  }
  to {
    transform: translate3d(16vw, -126vh, 0);
  }
}
.circle-container:nth-child(38) .circle {
  -webkit-animation-delay: 968ms;
  animation-delay: 968ms;
}
.circle-container:nth-child(39) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-39;
  animation-name: move-frames-39;
  -webkit-animation-duration: 20583ms;
  animation-duration: 20583ms;
  -webkit-animation-delay: 2835ms;
  animation-delay: 2835ms;
}
@-webkit-keyframes move-frames-39 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }
  to {
    transform: translate3d(85vw, -113vh, 0);
  }
}
@keyframes move-frames-39 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }
  to {
    transform: translate3d(85vw, -113vh, 0);
  }
}
.circle-container:nth-child(39) .circle {
  -webkit-animation-delay: 1383ms;
  animation-delay: 1383ms;
}
.circle-container:nth-child(40) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-40;
  animation-name: move-frames-40;
  -webkit-animation-duration: 20125ms;
  animation-duration: 20125ms;
  -webkit-animation-delay: 7846ms;
  animation-delay: 7846ms;
}
@-webkit-keyframes move-frames-40 {
  from {
    transform: translate3d(31vw, 109vh, 0);
  }
  to {
    transform: translate3d(34vw, -110vh, 0);
  }
}
@keyframes move-frames-40 {
  from {
    transform: translate3d(31vw, 109vh, 0);
  }
  to {
    transform: translate3d(34vw, -110vh, 0);
  }
}
.circle-container:nth-child(40) .circle {
  -webkit-animation-delay: 1951ms;
  animation-delay: 1951ms;
}
.circle-container:nth-child(41) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-41;
  animation-name: move-frames-41;
  -webkit-animation-duration: 22413ms;
  animation-duration: 22413ms;
  -webkit-animation-delay: 110ms;
  animation-delay: 110ms;
}
@-webkit-keyframes move-frames-41 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(94vw, -109vh, 0);
  }
}
@keyframes move-frames-41 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(94vw, -109vh, 0);
  }
}
.circle-container:nth-child(41) .circle {
  -webkit-animation-delay: 981ms;
  animation-delay: 981ms;
}
.circle-container:nth-child(42) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-42;
  animation-name: move-frames-42;
  -webkit-animation-duration: 16613ms;
  animation-duration: 16613ms;
  -webkit-animation-delay: 4793ms;
  animation-delay: 4793ms;
}
@-webkit-keyframes move-frames-42 {
  from {
    transform: translate3d(79vw, 104vh, 0);
  }
  to {
    transform: translate3d(49vw, -127vh, 0);
  }
}
@keyframes move-frames-42 {
  from {
    transform: translate3d(79vw, 104vh, 0);
  }
  to {
    transform: translate3d(49vw, -127vh, 0);
  }
}
.circle-container:nth-child(42) .circle {
  -webkit-animation-delay: 3559ms;
  animation-delay: 3559ms;
}
.circle-container:nth-child(43) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-43;
  animation-name: move-frames-43;
  -webkit-animation-duration: 21256ms;
  animation-duration: 21256ms;
  -webkit-animation-delay: 7891ms;
  animation-delay: 7891ms;
}
@-webkit-keyframes move-frames-43 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(79vw, -111vh, 0);
  }
}
@keyframes move-frames-43 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(79vw, -111vh, 0);
  }
}
.circle-container:nth-child(43) .circle {
  -webkit-animation-delay: 2939ms;
  animation-delay: 2939ms;
}
.circle-container:nth-child(44) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-44;
  animation-name: move-frames-44;
  -webkit-animation-duration: 20456ms;
  animation-duration: 20456ms;
  -webkit-animation-delay: 8905ms;
  animation-delay: 8905ms;
}
@-webkit-keyframes move-frames-44 {
  from {
    transform: translate3d(32vw, 101vh, 0);
  }
  to {
    transform: translate3d(86vw, -113vh, 0);
  }
}
@keyframes move-frames-44 {
  from {
    transform: translate3d(32vw, 101vh, 0);
  }
  to {
    transform: translate3d(86vw, -113vh, 0);
  }
}
.circle-container:nth-child(44) .circle {
  -webkit-animation-delay: 240ms;
  animation-delay: 240ms;
}
.circle-container:nth-child(45) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-45;
  animation-name: move-frames-45;
  -webkit-animation-duration: 14717ms;
  animation-duration: 14717ms;
  -webkit-animation-delay: 4099ms;
  animation-delay: 4099ms;
}
@-webkit-keyframes move-frames-45 {
  from {
    transform: translate3d(18vw, 108vh, 0);
  }
  to {
    transform: translate3d(17vw, -110vh, 0);
  }
}
@keyframes move-frames-45 {
  from {
    transform: translate3d(18vw, 108vh, 0);
  }
  to {
    transform: translate3d(17vw, -110vh, 0);
  }
}
.circle-container:nth-child(45) .circle {
  -webkit-animation-delay: 3431ms;
  animation-delay: 3431ms;
}
.circle-container:nth-child(46) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-46;
  animation-name: move-frames-46;
  -webkit-animation-duration: 23385ms;
  animation-duration: 23385ms;
  -webkit-animation-delay: 1929ms;
  animation-delay: 1929ms;
}
@-webkit-keyframes move-frames-46 {
  from {
    transform: translate3d(66vw, 105vh, 0);
  }
  to {
    transform: translate3d(55vw, -122vh, 0);
  }
}
@keyframes move-frames-46 {
  from {
    transform: translate3d(66vw, 105vh, 0);
  }
  to {
    transform: translate3d(55vw, -122vh, 0);
  }
}
.circle-container:nth-child(46) .circle {
  -webkit-animation-delay: 1827ms;
  animation-delay: 1827ms;
}
.circle-container:nth-child(47) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-47;
  animation-name: move-frames-47;
  -webkit-animation-duration: 20131ms;
  animation-duration: 20131ms;
  -webkit-animation-delay: 2337ms;
  animation-delay: 2337ms;
}
@-webkit-keyframes move-frames-47 {
  from {
    transform: translate3d(30vw, 104vh, 0);
  }
  to {
    transform: translate3d(38vw, -123vh, 0);
  }
}
@keyframes move-frames-47 {
  from {
    transform: translate3d(30vw, 104vh, 0);
  }
  to {
    transform: translate3d(38vw, -123vh, 0);
  }
}
.circle-container:nth-child(47) .circle {
  -webkit-animation-delay: 3348ms;
  animation-delay: 3348ms;
}
.circle-container:nth-child(48) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-48;
  animation-name: move-frames-48;
  -webkit-animation-duration: 15755ms;
  animation-duration: 15755ms;
  -webkit-animation-delay: 4865ms;
  animation-delay: 4865ms;
}
@-webkit-keyframes move-frames-48 {
  from {
    transform: translate3d(75vw, 106vh, 0);
  }
  to {
    transform: translate3d(86vw, -121vh, 0);
  }
}
@keyframes move-frames-48 {
  from {
    transform: translate3d(75vw, 106vh, 0);
  }
  to {
    transform: translate3d(86vw, -121vh, 0);
  }
}
.circle-container:nth-child(48) .circle {
  -webkit-animation-delay: 2662ms;
  animation-delay: 2662ms;
}
.circle-container:nth-child(49) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-49;
  animation-name: move-frames-49;
  -webkit-animation-duration: 21330ms;
  animation-duration: 21330ms;
  -webkit-animation-delay: 6782ms;
  animation-delay: 6782ms;
}
@-webkit-keyframes move-frames-49 {
  from {
    transform: translate3d(15vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
@keyframes move-frames-49 {
  from {
    transform: translate3d(15vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
.circle-container:nth-child(49) .circle {
  -webkit-animation-delay: 1294ms;
  animation-delay: 1294ms;
}
.circle-container:nth-child(50) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-50;
  animation-name: move-frames-50;
  -webkit-animation-duration: 21377ms;
  animation-duration: 21377ms;
  -webkit-animation-delay: 3916ms;
  animation-delay: 3916ms;
}
@-webkit-keyframes move-frames-50 {
  from {
    transform: translate3d(94vw, 102vh, 0);
  }
  to {
    transform: translate3d(27vw, -130vh, 0);
  }
}
@keyframes move-frames-50 {
  from {
    transform: translate3d(94vw, 102vh, 0);
  }
  to {
    transform: translate3d(27vw, -130vh, 0);
  }
}
.circle-container:nth-child(50) .circle {
  -webkit-animation-delay: 3786ms;
  animation-delay: 3786ms;
}
.circle-container:nth-child(51) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-51;
  animation-name: move-frames-51;
  -webkit-animation-duration: 23130ms;
  animation-duration: 23130ms;
  -webkit-animation-delay: 7520ms;
  animation-delay: 7520ms;
}
@-webkit-keyframes move-frames-51 {
  from {
    transform: translate3d(57vw, 106vh, 0);
  }
  to {
    transform: translate3d(94vw, -135vh, 0);
  }
}
@keyframes move-frames-51 {
  from {
    transform: translate3d(57vw, 106vh, 0);
  }
  to {
    transform: translate3d(94vw, -135vh, 0);
  }
}
.circle-container:nth-child(51) .circle {
  -webkit-animation-delay: 3923ms;
  animation-delay: 3923ms;
}
.circle-container:nth-child(52) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-52;
  animation-name: move-frames-52;
  -webkit-animation-duration: 20997ms;
  animation-duration: 20997ms;
  -webkit-animation-delay: 7275ms;
  animation-delay: 7275ms;
}
@-webkit-keyframes move-frames-52 {
  from {
    transform: translate3d(73vw, 102vh, 0);
  }
  to {
    transform: translate3d(18vw, -109vh, 0);
  }
}
@keyframes move-frames-52 {
  from {
    transform: translate3d(73vw, 102vh, 0);
  }
  to {
    transform: translate3d(18vw, -109vh, 0);
  }
}
.circle-container:nth-child(52) .circle {
  -webkit-animation-delay: 3768ms;
  animation-delay: 3768ms;
}
.circle-container:nth-child(53) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-53;
  animation-name: move-frames-53;
  -webkit-animation-duration: 17321ms;
  animation-duration: 17321ms;
  -webkit-animation-delay: 4154ms;
  animation-delay: 4154ms;
}
@-webkit-keyframes move-frames-53 {
  from {
    transform: translate3d(50vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -122vh, 0);
  }
}
@keyframes move-frames-53 {
  from {
    transform: translate3d(50vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -122vh, 0);
  }
}
.circle-container:nth-child(53) .circle {
  -webkit-animation-delay: 1116ms;
  animation-delay: 1116ms;
}
.circle-container:nth-child(54) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-54;
  animation-name: move-frames-54;
  -webkit-animation-duration: 19104ms;
  animation-duration: 19104ms;
  -webkit-animation-delay: 1601ms;
  animation-delay: 1601ms;
}
@-webkit-keyframes move-frames-54 {
  from {
    transform: translate3d(39vw, 105vh, 0);
  }
  to {
    transform: translate3d(21vw, -130vh, 0);
  }
}
@keyframes move-frames-54 {
  from {
    transform: translate3d(39vw, 105vh, 0);
  }
  to {
    transform: translate3d(21vw, -130vh, 0);
  }
}
.circle-container:nth-child(54) .circle {
  -webkit-animation-delay: 501ms;
  animation-delay: 501ms;
}
.circle-container:nth-child(55) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-55;
  animation-name: move-frames-55;
  -webkit-animation-duration: 18920ms;
  animation-duration: 18920ms;
  -webkit-animation-delay: 4765ms;
  animation-delay: 4765ms;
}
@-webkit-keyframes move-frames-55 {
  from {
    transform: translate3d(82vw, 104vh, 0);
  }
  to {
    transform: translate3d(60vw, -120vh, 0);
  }
}
@keyframes move-frames-55 {
  from {
    transform: translate3d(82vw, 104vh, 0);
  }
  to {
    transform: translate3d(60vw, -120vh, 0);
  }
}
.circle-container:nth-child(55) .circle {
  -webkit-animation-delay: 1164ms;
  animation-delay: 1164ms;
}
.circle-container:nth-child(56) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-56;
  animation-name: move-frames-56;
  -webkit-animation-duration: 17231ms;
  animation-duration: 17231ms;
  -webkit-animation-delay: 9714ms;
  animation-delay: 9714ms;
}
@-webkit-keyframes move-frames-56 {
  from {
    transform: translate3d(98vw, 108vh, 0);
  }
  to {
    transform: translate3d(87vw, -112vh, 0);
  }
}
@keyframes move-frames-56 {
  from {
    transform: translate3d(98vw, 108vh, 0);
  }
  to {
    transform: translate3d(87vw, -112vh, 0);
  }
}
.circle-container:nth-child(56) .circle {
  -webkit-animation-delay: 2413ms;
  animation-delay: 2413ms;
}
.circle-container:nth-child(57) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-57;
  animation-name: move-frames-57;
  -webkit-animation-duration: 21240ms;
  animation-duration: 21240ms;
  -webkit-animation-delay: 9188ms;
  animation-delay: 9188ms;
}
@-webkit-keyframes move-frames-57 {
  from {
    transform: translate3d(12vw, 108vh, 0);
  }
  to {
    transform: translate3d(39vw, -130vh, 0);
  }
}
@keyframes move-frames-57 {
  from {
    transform: translate3d(12vw, 108vh, 0);
  }
  to {
    transform: translate3d(39vw, -130vh, 0);
  }
}
.circle-container:nth-child(57) .circle {
  -webkit-animation-delay: 1530ms;
  animation-delay: 1530ms;
}
.circle-container:nth-child(58) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-58;
  animation-name: move-frames-58;
  -webkit-animation-duration: 18728ms;
  animation-duration: 18728ms;
  -webkit-animation-delay: 876ms;
  animation-delay: 876ms;
}
@-webkit-keyframes move-frames-58 {
  from {
    transform: translate3d(85vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -116vh, 0);
  }
}
@keyframes move-frames-58 {
  from {
    transform: translate3d(85vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -116vh, 0);
  }
}
.circle-container:nth-child(58) .circle {
  -webkit-animation-delay: 2178ms;
  animation-delay: 2178ms;
}
.circle-container:nth-child(59) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-59;
  animation-name: move-frames-59;
  -webkit-animation-duration: 20058ms;
  animation-duration: 20058ms;
  -webkit-animation-delay: 1473ms;
  animation-delay: 1473ms;
}
@-webkit-keyframes move-frames-59 {
  from {
    transform: translate3d(7vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -110vh, 0);
  }
}
@keyframes move-frames-59 {
  from {
    transform: translate3d(7vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -110vh, 0);
  }
}
.circle-container:nth-child(59) .circle {
  -webkit-animation-delay: 795ms;
  animation-delay: 795ms;
}
.circle-container:nth-child(60) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-60;
  animation-name: move-frames-60;
  -webkit-animation-duration: 15778ms;
  animation-duration: 15778ms;
  -webkit-animation-delay: 3888ms;
  animation-delay: 3888ms;
}
@-webkit-keyframes move-frames-60 {
  from {
    transform: translate3d(78vw, 104vh, 0);
  }
  to {
    transform: translate3d(99vw, -132vh, 0);
  }
}
@keyframes move-frames-60 {
  from {
    transform: translate3d(78vw, 104vh, 0);
  }
  to {
    transform: translate3d(99vw, -132vh, 0);
  }
}
.circle-container:nth-child(60) .circle {
  -webkit-animation-delay: 42ms;
  animation-delay: 42ms;
}
.circle-container:nth-child(61) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-61;
  animation-name: move-frames-61;
  -webkit-animation-duration: 21666ms;
  animation-duration: 21666ms;
  -webkit-animation-delay: 4023ms;
  animation-delay: 4023ms;
}
@-webkit-keyframes move-frames-61 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }
  to {
    transform: translate3d(30vw, -126vh, 0);
  }
}
@keyframes move-frames-61 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }
  to {
    transform: translate3d(30vw, -126vh, 0);
  }
}
.circle-container:nth-child(61) .circle {
  -webkit-animation-delay: 409ms;
  animation-delay: 409ms;
}
.circle-container:nth-child(62) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-62;
  animation-name: move-frames-62;
  -webkit-animation-duration: 19515ms;
  animation-duration: 19515ms;
  -webkit-animation-delay: 1092ms;
  animation-delay: 1092ms;
}
@-webkit-keyframes move-frames-62 {
  from {
    transform: translate3d(43vw, 108vh, 0);
  }
  to {
    transform: translate3d(1vw, -135vh, 0);
  }
}
@keyframes move-frames-62 {
  from {
    transform: translate3d(43vw, 108vh, 0);
  }
  to {
    transform: translate3d(1vw, -135vh, 0);
  }
}
.circle-container:nth-child(62) .circle {
  -webkit-animation-delay: 1655ms;
  animation-delay: 1655ms;
}
.circle-container:nth-child(63) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-63;
  animation-name: move-frames-63;
  -webkit-animation-duration: 18035ms;
  animation-duration: 18035ms;
  -webkit-animation-delay: 6554ms;
  animation-delay: 6554ms;
}
@-webkit-keyframes move-frames-63 {
  from {
    transform: translate3d(13vw, 101vh, 0);
  }
  to {
    transform: translate3d(16vw, -111vh, 0);
  }
}
@keyframes move-frames-63 {
  from {
    transform: translate3d(13vw, 101vh, 0);
  }
  to {
    transform: translate3d(16vw, -111vh, 0);
  }
}
.circle-container:nth-child(63) .circle {
  -webkit-animation-delay: 3554ms;
  animation-delay: 3554ms;
}
.circle-container:nth-child(64) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-64;
  animation-name: move-frames-64;
  -webkit-animation-duration: 18453ms;
  animation-duration: 18453ms;
  -webkit-animation-delay: 379ms;
  animation-delay: 379ms;
}
@-webkit-keyframes move-frames-64 {
  from {
    transform: translate3d(28vw, 107vh, 0);
  }
  to {
    transform: translate3d(87vw, -109vh, 0);
  }
}
@keyframes move-frames-64 {
  from {
    transform: translate3d(28vw, 107vh, 0);
  }
  to {
    transform: translate3d(87vw, -109vh, 0);
  }
}
.circle-container:nth-child(64) .circle {
  -webkit-animation-delay: 3489ms;
  animation-delay: 3489ms;
}
.circle-container:nth-child(65) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-65;
  animation-name: move-frames-65;
  -webkit-animation-duration: 17975ms;
  animation-duration: 17975ms;
  -webkit-animation-delay: 10402ms;
  animation-delay: 10402ms;
}
@-webkit-keyframes move-frames-65 {
  from {
    transform: translate3d(2vw, 104vh, 0);
  }
  to {
    transform: translate3d(59vw, -116vh, 0);
  }
}
@keyframes move-frames-65 {
  from {
    transform: translate3d(2vw, 104vh, 0);
  }
  to {
    transform: translate3d(59vw, -116vh, 0);
  }
}
.circle-container:nth-child(65) .circle {
  -webkit-animation-delay: 2620ms;
  animation-delay: 2620ms;
}
.circle-container:nth-child(66) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-66;
  animation-name: move-frames-66;
  -webkit-animation-duration: 18394ms;
  animation-duration: 18394ms;
  -webkit-animation-delay: 5699ms;
  animation-delay: 5699ms;
}
@-webkit-keyframes move-frames-66 {
  from {
    transform: translate3d(100vw, 103vh, 0);
  }
  to {
    transform: translate3d(43vw, -107vh, 0);
  }
}
@keyframes move-frames-66 {
  from {
    transform: translate3d(100vw, 103vh, 0);
  }
  to {
    transform: translate3d(43vw, -107vh, 0);
  }
}
.circle-container:nth-child(66) .circle {
  -webkit-animation-delay: 2581ms;
  animation-delay: 2581ms;
}
.circle-container:nth-child(67) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-67;
  animation-name: move-frames-67;
  -webkit-animation-duration: 15125ms;
  animation-duration: 15125ms;
  -webkit-animation-delay: 8246ms;
  animation-delay: 8246ms;
}
@-webkit-keyframes move-frames-67 {
  from {
    transform: translate3d(90vw, 108vh, 0);
  }
  to {
    transform: translate3d(3vw, -138vh, 0);
  }
}
@keyframes move-frames-67 {
  from {
    transform: translate3d(90vw, 108vh, 0);
  }
  to {
    transform: translate3d(3vw, -138vh, 0);
  }
}
.circle-container:nth-child(67) .circle {
  -webkit-animation-delay: 2495ms;
  animation-delay: 2495ms;
}
.circle-container:nth-child(68) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-68;
  animation-name: move-frames-68;
  -webkit-animation-duration: 19500ms;
  animation-duration: 19500ms;
  -webkit-animation-delay: 10412ms;
  animation-delay: 10412ms;
}
@-webkit-keyframes move-frames-68 {
  from {
    transform: translate3d(81vw, 101vh, 0);
  }
  to {
    transform: translate3d(80vw, -113vh, 0);
  }
}
@keyframes move-frames-68 {
  from {
    transform: translate3d(81vw, 101vh, 0);
  }
  to {
    transform: translate3d(80vw, -113vh, 0);
  }
}
.circle-container:nth-child(68) .circle {
  -webkit-animation-delay: 2951ms;
  animation-delay: 2951ms;
}
.circle-container:nth-child(69) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-69;
  animation-name: move-frames-69;
  -webkit-animation-duration: 15199ms;
  animation-duration: 15199ms;
  -webkit-animation-delay: 6831ms;
  animation-delay: 6831ms;
}
@-webkit-keyframes move-frames-69 {
  from {
    transform: translate3d(53vw, 106vh, 0);
  }
  to {
    transform: translate3d(79vw, -122vh, 0);
  }
}
@keyframes move-frames-69 {
  from {
    transform: translate3d(53vw, 106vh, 0);
  }
  to {
    transform: translate3d(79vw, -122vh, 0);
  }
}
.circle-container:nth-child(69) .circle {
  -webkit-animation-delay: 3531ms;
  animation-delay: 3531ms;
}
.circle-container:nth-child(70) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-70;
  animation-name: move-frames-70;
  -webkit-animation-duration: 20879ms;
  animation-duration: 20879ms;
  -webkit-animation-delay: 8283ms;
  animation-delay: 8283ms;
}
@-webkit-keyframes move-frames-70 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(81vw, -112vh, 0);
  }
}
@keyframes move-frames-70 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(81vw, -112vh, 0);
  }
}
.circle-container:nth-child(70) .circle {
  -webkit-animation-delay: 953ms;
  animation-delay: 953ms;
}
.circle-container:nth-child(71) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-71;
  animation-name: move-frames-71;
  -webkit-animation-duration: 19027ms;
  animation-duration: 19027ms;
  -webkit-animation-delay: 10190ms;
  animation-delay: 10190ms;
}
@-webkit-keyframes move-frames-71 {
  from {
    transform: translate3d(30vw, 109vh, 0);
  }
  to {
    transform: translate3d(11vw, -134vh, 0);
  }
}
@keyframes move-frames-71 {
  from {
    transform: translate3d(30vw, 109vh, 0);
  }
  to {
    transform: translate3d(11vw, -134vh, 0);
  }
}
.circle-container:nth-child(71) .circle {
  -webkit-animation-delay: 2705ms;
  animation-delay: 2705ms;
}
.circle-container:nth-child(72) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-72;
  animation-name: move-frames-72;
  -webkit-animation-duration: 18147ms;
  animation-duration: 18147ms;
  -webkit-animation-delay: 6668ms;
  animation-delay: 6668ms;
}
@-webkit-keyframes move-frames-72 {
  from {
    transform: translate3d(99vw, 101vh, 0);
  }
  to {
    transform: translate3d(27vw, -108vh, 0);
  }
}
@keyframes move-frames-72 {
  from {
    transform: translate3d(99vw, 101vh, 0);
  }
  to {
    transform: translate3d(27vw, -108vh, 0);
  }
}
.circle-container:nth-child(72) .circle {
  -webkit-animation-delay: 1304ms;
  animation-delay: 1304ms;
}
.circle-container:nth-child(73) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-73;
  animation-name: move-frames-73;
  -webkit-animation-duration: 18668ms;
  animation-duration: 18668ms;
  -webkit-animation-delay: 2963ms;
  animation-delay: 2963ms;
}
@-webkit-keyframes move-frames-73 {
  from {
    transform: translate3d(69vw, 103vh, 0);
  }
  to {
    transform: translate3d(1vw, -121vh, 0);
  }
}
@keyframes move-frames-73 {
  from {
    transform: translate3d(69vw, 103vh, 0);
  }
  to {
    transform: translate3d(1vw, -121vh, 0);
  }
}
.circle-container:nth-child(73) .circle {
  -webkit-animation-delay: 1302ms;
  animation-delay: 1302ms;
}
.circle-container:nth-child(74) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-74;
  animation-name: move-frames-74;
  -webkit-animation-duration: 16412ms;
  animation-duration: 16412ms;
  -webkit-animation-delay: 6188ms;
  animation-delay: 6188ms;
}
@-webkit-keyframes move-frames-74 {
  from {
    transform: translate3d(17vw, 102vh, 0);
  }
  to {
    transform: translate3d(30vw, -108vh, 0);
  }
}
@keyframes move-frames-74 {
  from {
    transform: translate3d(17vw, 102vh, 0);
  }
  to {
    transform: translate3d(30vw, -108vh, 0);
  }
}
.circle-container:nth-child(74) .circle {
  -webkit-animation-delay: 2795ms;
  animation-delay: 2795ms;
}
.circle-container:nth-child(75) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-75;
  animation-name: move-frames-75;
  -webkit-animation-duration: 14393ms;
  animation-duration: 14393ms;
  -webkit-animation-delay: 2448ms;
  animation-delay: 2448ms;
}
@-webkit-keyframes move-frames-75 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }
  to {
    transform: translate3d(10vw, -122vh, 0);
  }
}
@keyframes move-frames-75 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }
  to {
    transform: translate3d(10vw, -122vh, 0);
  }
}
.circle-container:nth-child(75) .circle {
  -webkit-animation-delay: 674ms;
  animation-delay: 674ms;
}
.circle-container:nth-child(76) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-76;
  animation-name: move-frames-76;
  -webkit-animation-duration: 17884ms;
  animation-duration: 17884ms;
  -webkit-animation-delay: 3860ms;
  animation-delay: 3860ms;
}
@-webkit-keyframes move-frames-76 {
  from {
    transform: translate3d(51vw, 106vh, 0);
  }
  to {
    transform: translate3d(23vw, -129vh, 0);
  }
}
@keyframes move-frames-76 {
  from {
    transform: translate3d(51vw, 106vh, 0);
  }
  to {
    transform: translate3d(23vw, -129vh, 0);
  }
}
.circle-container:nth-child(76) .circle {
  -webkit-animation-delay: 3520ms;
  animation-delay: 3520ms;
}
.circle-container:nth-child(77) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-77;
  animation-name: move-frames-77;
  -webkit-animation-duration: 15659ms;
  animation-duration: 15659ms;
  -webkit-animation-delay: 8092ms;
  animation-delay: 8092ms;
}
@-webkit-keyframes move-frames-77 {
  from {
    transform: translate3d(13vw, 106vh, 0);
  }
  to {
    transform: translate3d(16vw, -118vh, 0);
  }
}
@keyframes move-frames-77 {
  from {
    transform: translate3d(13vw, 106vh, 0);
  }
  to {
    transform: translate3d(16vw, -118vh, 0);
  }
}
.circle-container:nth-child(77) .circle {
  -webkit-animation-delay: 3754ms;
  animation-delay: 3754ms;
}
.circle-container:nth-child(78) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-78;
  animation-name: move-frames-78;
  -webkit-animation-duration: 19758ms;
  animation-duration: 19758ms;
  -webkit-animation-delay: 5994ms;
  animation-delay: 5994ms;
}
@-webkit-keyframes move-frames-78 {
  from {
    transform: translate3d(91vw, 106vh, 0);
  }
  to {
    transform: translate3d(87vw, -134vh, 0);
  }
}
@keyframes move-frames-78 {
  from {
    transform: translate3d(91vw, 106vh, 0);
  }
  to {
    transform: translate3d(87vw, -134vh, 0);
  }
}
.circle-container:nth-child(78) .circle {
  -webkit-animation-delay: 3560ms;
  animation-delay: 3560ms;
}
.circle-container:nth-child(79) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-79;
  animation-name: move-frames-79;
  -webkit-animation-duration: 19425ms;
  animation-duration: 19425ms;
  -webkit-animation-delay: 9618ms;
  animation-delay: 9618ms;
}
@-webkit-keyframes move-frames-79 {
  from {
    transform: translate3d(75vw, 106vh, 0);
  }
  to {
    transform: translate3d(100vw, -111vh, 0);
  }
}
@keyframes move-frames-79 {
  from {
    transform: translate3d(75vw, 106vh, 0);
  }
  to {
    transform: translate3d(100vw, -111vh, 0);
  }
}
.circle-container:nth-child(79) .circle {
  -webkit-animation-delay: 2226ms;
  animation-delay: 2226ms;
}
.circle-container:nth-child(80) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-80;
  animation-name: move-frames-80;
  -webkit-animation-duration: 18278ms;
  animation-duration: 18278ms;
  -webkit-animation-delay: 5633ms;
  animation-delay: 5633ms;
}
@-webkit-keyframes move-frames-80 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }
  to {
    transform: translate3d(96vw, -112vh, 0);
  }
}
@keyframes move-frames-80 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }
  to {
    transform: translate3d(96vw, -112vh, 0);
  }
}
.circle-container:nth-child(80) .circle {
  -webkit-animation-delay: 369ms;
  animation-delay: 369ms;
}
.circle-container:nth-child(81) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-81;
  animation-name: move-frames-81;
  -webkit-animation-duration: 15021ms;
  animation-duration: 15021ms;
  -webkit-animation-delay: 3270ms;
  animation-delay: 3270ms;
}
@-webkit-keyframes move-frames-81 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }
  to {
    transform: translate3d(20vw, -135vh, 0);
  }
}
@keyframes move-frames-81 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }
  to {
    transform: translate3d(20vw, -135vh, 0);
  }
}
.circle-container:nth-child(81) .circle {
  -webkit-animation-delay: 2119ms;
  animation-delay: 2119ms;
}
.circle-container:nth-child(82) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-82;
  animation-name: move-frames-82;
  -webkit-animation-duration: 23967ms;
  animation-duration: 23967ms;
  -webkit-animation-delay: 5262ms;
  animation-delay: 5262ms;
}
@-webkit-keyframes move-frames-82 {
  from {
    transform: translate3d(22vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -110vh, 0);
  }
}
@keyframes move-frames-82 {
  from {
    transform: translate3d(22vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -110vh, 0);
  }
}
.circle-container:nth-child(82) .circle {
  -webkit-animation-delay: 538ms;
  animation-delay: 538ms;
}
.circle-container:nth-child(83) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-83;
  animation-name: move-frames-83;
  -webkit-animation-duration: 20813ms;
  animation-duration: 20813ms;
  -webkit-animation-delay: 8714ms;
  animation-delay: 8714ms;
}
@-webkit-keyframes move-frames-83 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(93vw, -119vh, 0);
  }
}
@keyframes move-frames-83 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(93vw, -119vh, 0);
  }
}
.circle-container:nth-child(83) .circle {
  -webkit-animation-delay: 810ms;
  animation-delay: 810ms;
}
.circle-container:nth-child(84) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-84;
  animation-name: move-frames-84;
  -webkit-animation-duration: 21038ms;
  animation-duration: 21038ms;
  -webkit-animation-delay: 1408ms;
  animation-delay: 1408ms;
}
@-webkit-keyframes move-frames-84 {
  from {
    transform: translate3d(38vw, 108vh, 0);
  }
  to {
    transform: translate3d(97vw, -133vh, 0);
  }
}
@keyframes move-frames-84 {
  from {
    transform: translate3d(38vw, 108vh, 0);
  }
  to {
    transform: translate3d(97vw, -133vh, 0);
  }
}
.circle-container:nth-child(84) .circle {
  -webkit-animation-delay: 2928ms;
  animation-delay: 2928ms;
}
.circle-container:nth-child(85) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-85;
  animation-name: move-frames-85;
  -webkit-animation-duration: 22601ms;
  animation-duration: 22601ms;
  -webkit-animation-delay: 113ms;
  animation-delay: 113ms;
}
@-webkit-keyframes move-frames-85 {
  from {
    transform: translate3d(21vw, 107vh, 0);
  }
  to {
    transform: translate3d(92vw, -134vh, 0);
  }
}
@keyframes move-frames-85 {
  from {
    transform: translate3d(21vw, 107vh, 0);
  }
  to {
    transform: translate3d(92vw, -134vh, 0);
  }
}
.circle-container:nth-child(85) .circle {
  -webkit-animation-delay: 3477ms;
  animation-delay: 3477ms;
}
.circle-container:nth-child(86) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-86;
  animation-name: move-frames-86;
  -webkit-animation-duration: 17877ms;
  animation-duration: 17877ms;
  -webkit-animation-delay: 2466ms;
  animation-delay: 2466ms;
}
@-webkit-keyframes move-frames-86 {
  from {
    transform: translate3d(73vw, 102vh, 0);
  }
  to {
    transform: translate3d(95vw, -115vh, 0);
  }
}
@keyframes move-frames-86 {
  from {
    transform: translate3d(73vw, 102vh, 0);
  }
  to {
    transform: translate3d(95vw, -115vh, 0);
  }
}
.circle-container:nth-child(86) .circle {
  -webkit-animation-delay: 3802ms;
  animation-delay: 3802ms;
}
.circle-container:nth-child(87) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-87;
  animation-name: move-frames-87;
  -webkit-animation-duration: 18204ms;
  animation-duration: 18204ms;
  -webkit-animation-delay: 8073ms;
  animation-delay: 8073ms;
}
@-webkit-keyframes move-frames-87 {
  from {
    transform: translate3d(42vw, 108vh, 0);
  }
  to {
    transform: translate3d(24vw, -117vh, 0);
  }
}
@keyframes move-frames-87 {
  from {
    transform: translate3d(42vw, 108vh, 0);
  }
  to {
    transform: translate3d(24vw, -117vh, 0);
  }
}
.circle-container:nth-child(87) .circle {
  -webkit-animation-delay: 2417ms;
  animation-delay: 2417ms;
}
.circle-container:nth-child(88) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-88;
  animation-name: move-frames-88;
  -webkit-animation-duration: 22076ms;
  animation-duration: 22076ms;
  -webkit-animation-delay: 3455ms;
  animation-delay: 3455ms;
}
@-webkit-keyframes move-frames-88 {
  from {
    transform: translate3d(24vw, 107vh, 0);
  }
  to {
    transform: translate3d(62vw, -116vh, 0);
  }
}
@keyframes move-frames-88 {
  from {
    transform: translate3d(24vw, 107vh, 0);
  }
  to {
    transform: translate3d(62vw, -116vh, 0);
  }
}
.circle-container:nth-child(88) .circle {
  -webkit-animation-delay: 1957ms;
  animation-delay: 1957ms;
}
.circle-container:nth-child(89) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-89;
  animation-name: move-frames-89;
  -webkit-animation-duration: 18673ms;
  animation-duration: 18673ms;
  -webkit-animation-delay: 645ms;
  animation-delay: 645ms;
}
@-webkit-keyframes move-frames-89 {
  from {
    transform: translate3d(97vw, 105vh, 0);
  }
  to {
    transform: translate3d(36vw, -108vh, 0);
  }
}
@keyframes move-frames-89 {
  from {
    transform: translate3d(97vw, 105vh, 0);
  }
  to {
    transform: translate3d(36vw, -108vh, 0);
  }
}
.circle-container:nth-child(89) .circle {
  -webkit-animation-delay: 676ms;
  animation-delay: 676ms;
}
.circle-container:nth-child(90) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-90;
  animation-name: move-frames-90;
  -webkit-animation-duration: 22835ms;
  animation-duration: 22835ms;
  -webkit-animation-delay: 4099ms;
  animation-delay: 4099ms;
}
@-webkit-keyframes move-frames-90 {
  from {
    transform: translate3d(13vw, 104vh, 0);
  }
  to {
    transform: translate3d(77vw, -131vh, 0);
  }
}
@keyframes move-frames-90 {
  from {
    transform: translate3d(13vw, 104vh, 0);
  }
  to {
    transform: translate3d(77vw, -131vh, 0);
  }
}
.circle-container:nth-child(90) .circle {
  -webkit-animation-delay: 3139ms;
  animation-delay: 3139ms;
}
.circle-container:nth-child(91) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-91;
  animation-name: move-frames-91;
  -webkit-animation-duration: 22931ms;
  animation-duration: 22931ms;
  -webkit-animation-delay: 6570ms;
  animation-delay: 6570ms;
}
@-webkit-keyframes move-frames-91 {
  from {
    transform: translate3d(67vw, 105vh, 0);
  }
  to {
    transform: translate3d(83vw, -117vh, 0);
  }
}
@keyframes move-frames-91 {
  from {
    transform: translate3d(67vw, 105vh, 0);
  }
  to {
    transform: translate3d(83vw, -117vh, 0);
  }
}
.circle-container:nth-child(91) .circle {
  -webkit-animation-delay: 3558ms;
  animation-delay: 3558ms;
}
.circle-container:nth-child(92) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-92;
  animation-name: move-frames-92;
  -webkit-animation-duration: 14422ms;
  animation-duration: 14422ms;
  -webkit-animation-delay: 1423ms;
  animation-delay: 1423ms;
}
@-webkit-keyframes move-frames-92 {
  from {
    transform: translate3d(72vw, 108vh, 0);
  }
  to {
    transform: translate3d(31vw, -138vh, 0);
  }
}
@keyframes move-frames-92 {
  from {
    transform: translate3d(72vw, 108vh, 0);
  }
  to {
    transform: translate3d(31vw, -138vh, 0);
  }
}
.circle-container:nth-child(92) .circle {
  -webkit-animation-delay: 1305ms;
  animation-delay: 1305ms;
}
.circle-container:nth-child(93) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-93;
  animation-name: move-frames-93;
  -webkit-animation-duration: 16590ms;
  animation-duration: 16590ms;
  -webkit-animation-delay: 5928ms;
  animation-delay: 5928ms;
}
@-webkit-keyframes move-frames-93 {
  from {
    transform: translate3d(6vw, 109vh, 0);
  }
  to {
    transform: translate3d(24vw, -110vh, 0);
  }
}
@keyframes move-frames-93 {
  from {
    transform: translate3d(6vw, 109vh, 0);
  }
  to {
    transform: translate3d(24vw, -110vh, 0);
  }
}
.circle-container:nth-child(93) .circle {
  -webkit-animation-delay: 122ms;
  animation-delay: 122ms;
}
.circle-container:nth-child(94) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-94;
  animation-name: move-frames-94;
  -webkit-animation-duration: 23150ms;
  animation-duration: 23150ms;
  -webkit-animation-delay: 7887ms;
  animation-delay: 7887ms;
}
@-webkit-keyframes move-frames-94 {
  from {
    transform: translate3d(58vw, 108vh, 0);
  }
  to {
    transform: translate3d(25vw, -138vh, 0);
  }
}
@keyframes move-frames-94 {
  from {
    transform: translate3d(58vw, 108vh, 0);
  }
  to {
    transform: translate3d(25vw, -138vh, 0);
  }
}
.circle-container:nth-child(94) .circle {
  -webkit-animation-delay: 3640ms;
  animation-delay: 3640ms;
}
.circle-container:nth-child(95) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-95;
  animation-name: move-frames-95;
  -webkit-animation-duration: 16329ms;
  animation-duration: 16329ms;
  -webkit-animation-delay: 2592ms;
  animation-delay: 2592ms;
}
@-webkit-keyframes move-frames-95 {
  from {
    transform: translate3d(12vw, 102vh, 0);
  }
  to {
    transform: translate3d(91vw, -112vh, 0);
  }
}
@keyframes move-frames-95 {
  from {
    transform: translate3d(12vw, 102vh, 0);
  }
  to {
    transform: translate3d(91vw, -112vh, 0);
  }
}
.circle-container:nth-child(95) .circle {
  -webkit-animation-delay: 2390ms;
  animation-delay: 2390ms;
}
.circle-container:nth-child(96) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-96;
  animation-name: move-frames-96;
  -webkit-animation-duration: 15484ms;
  animation-duration: 15484ms;
  -webkit-animation-delay: 9087ms;
  animation-delay: 9087ms;
}
@-webkit-keyframes move-frames-96 {
  from {
    transform: translate3d(65vw, 110vh, 0);
  }
  to {
    transform: translate3d(58vw, -113vh, 0);
  }
}
@keyframes move-frames-96 {
  from {
    transform: translate3d(65vw, 110vh, 0);
  }
  to {
    transform: translate3d(58vw, -113vh, 0);
  }
}
.circle-container:nth-child(96) .circle {
  -webkit-animation-delay: 3870ms;
  animation-delay: 3870ms;
}
.circle-container:nth-child(97) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-97;
  animation-name: move-frames-97;
  -webkit-animation-duration: 21541ms;
  animation-duration: 21541ms;
  -webkit-animation-delay: 3653ms;
  animation-delay: 3653ms;
}
@-webkit-keyframes move-frames-97 {
  from {
    transform: translate3d(78vw, 110vh, 0);
  }
  to {
    transform: translate3d(29vw, -137vh, 0);
  }
}
@keyframes move-frames-97 {
  from {
    transform: translate3d(78vw, 110vh, 0);
  }
  to {
    transform: translate3d(29vw, -137vh, 0);
  }
}
.circle-container:nth-child(97) .circle {
  -webkit-animation-delay: 165ms;
  animation-delay: 165ms;
}
.circle-container:nth-child(98) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-98;
  animation-name: move-frames-98;
  -webkit-animation-duration: 18323ms;
  animation-duration: 18323ms;
  -webkit-animation-delay: 10112ms;
  animation-delay: 10112ms;
}
@-webkit-keyframes move-frames-98 {
  from {
    transform: translate3d(29vw, 109vh, 0);
  }
  to {
    transform: translate3d(91vw, -112vh, 0);
  }
}
@keyframes move-frames-98 {
  from {
    transform: translate3d(29vw, 109vh, 0);
  }
  to {
    transform: translate3d(91vw, -112vh, 0);
  }
}
.circle-container:nth-child(98) .circle {
  -webkit-animation-delay: 3268ms;
  animation-delay: 3268ms;
}
.circle-container:nth-child(99) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-99;
  animation-name: move-frames-99;
  -webkit-animation-duration: 22902ms;
  animation-duration: 22902ms;
  -webkit-animation-delay: 8470ms;
  animation-delay: 8470ms;
}
@-webkit-keyframes move-frames-99 {
  from {
    transform: translate3d(5vw, 104vh, 0);
  }
  to {
    transform: translate3d(50vw, -134vh, 0);
  }
}
@keyframes move-frames-99 {
  from {
    transform: translate3d(5vw, 104vh, 0);
  }
  to {
    transform: translate3d(50vw, -134vh, 0);
  }
}
.circle-container:nth-child(99) .circle {
  -webkit-animation-delay: 1734ms;
  animation-delay: 1734ms;
}
.circle-container:nth-child(100) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-100;
  animation-name: move-frames-100;
  -webkit-animation-duration: 21486ms;
  animation-duration: 21486ms;
  -webkit-animation-delay: 6406ms;
  animation-delay: 6406ms;
}
@-webkit-keyframes move-frames-100 {
  from {
    transform: translate3d(54vw, 102vh, 0);
  }
  to {
    transform: translate3d(27vw, -119vh, 0);
  }
}
@keyframes move-frames-100 {
  from {
    transform: translate3d(54vw, 102vh, 0);
  }
  to {
    transform: translate3d(27vw, -119vh, 0);
  }
}
.circle-container:nth-child(100) .circle {
  -webkit-animation-delay: 675ms;
  animation-delay: 675ms;
}
